import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import Logo from './Logo';
import LinkSelector from './LinkSelector';
import { Offcanvas } from '../Offcanvas/Offcanvas';
import { CategoryLink, InternalLink } from '../form-elements/Link';
import Button from '../form-elements/Button';
import { categoriesSelector } from '../../../modules/selectors/standort/categories';
import { standortUrlSelector } from '../../../modules/selectors/url';
import { categoryUrlSelector } from '../../../modules/selectors/url';
import ShopMenuList from './ShopMenuList';
import { carDealerLinkSelector } from '../../../modules/selectors/standort/theme';
import HolidayBanner from './HolidayBanner';
import loadable from '@loadable/component';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import { apiClient } from '../../../helpers/apiHelperV2';
import useSWR from 'swr';
import { saveCouponBannerHeight } from '../../../modules/actions/couponBannerHeight';
import { setServiceNavigatorStatus } from '../../../modules/actions/standort/service/serviceNavigator';

const CouponBanner = loadable(() => import('../CouponBanner/Couponbanner'));

function DefaultHeader({ links }) {
    const categories = useSelector(categoriesSelector);
    const categoryUrl = useSelector(categoryUrlSelector);
    const carDealerLink = useSelector(carDealerLinkSelector);
    const [isNavSidebarVisible, setIsNavSidebarVisible] = useState(false);
    const markenStandort = useSelector(markenStandortSelector);
    const standortUrl = useSelector(standortUrlSelector);
    const coupons = useSelector(state => state.standort[standortUrl].coupons);
    const [couponBannerActive, setCouponBannerActive] = useState(false);
    const dispatch = useDispatch();

    const closeSidebar = useCallback(() => {
        setIsNavSidebarVisible(false);
    }, []);

    const openSidebar = useCallback(() => {
        setIsNavSidebarVisible(true);
    }, []);

    const { data: serviceNavigatorstatus } = useSWR(`/api/v1/getServicenavigatorStatus?standortId=${markenStandort._id}`, async url => {
        const { data } = await apiClient.get(url);
        return data;
    });

    const serviceNavigatorStatus = useMemo(() => serviceNavigatorstatus?.status ?? [], [serviceNavigatorstatus]);

    if (serviceNavigatorStatus !== undefined) {
        dispatch(setServiceNavigatorStatus(serviceNavigatorStatus));
    }

    const { reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung } = useMemo(() => {
        if (!serviceNavigatorStatus) {
            const [reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung] = _.partition(categories, { url: 'reparatur-wartung' });
            return { reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung };
        } else {
            const [reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung] = _.partition(categories, { url: '' });
            return { reparaturUndWartungCategory, categoriesWithoutReparaturUndWartung };
        }

    }, [categories, serviceNavigatorStatus]);

    const checkIfCouponBannerActive = () => {
        return coupons?.data?.some(coupon => { return coupon.banner.isActive === true })
    }

    useEffect(() => {
        let isActive = checkIfCouponBannerActive();
        setCouponBannerActive(isActive);
        dispatch(saveCouponBannerHeight(isActive ? 36 : 0));
    }, [coupons]);

    const isLinkActive = category => {
        return category.url === categoryUrl || category.subCategories.find(subCategory => subCategory.url === categoryUrl);
    };

    return (
        <>
            <div className="uk-sticky-fixed uk-width-1-1" style={{ height: couponBannerActive ? 106 : 70, maxHeight: 120, position: 'sticky', top: 0 }}>
                <div className="uk-navbar-container uk-navbar-sticky">
                    <div className="uk-container">
                        <div className="uk-flex uk-flex-middle uk-flex-between">
                            <div className="uk-width-auto" style={{ maxWidth: '60%' }}>
                                <Logo />
                            </div>
                            <nav className="uk-navbar" data-uk-navbar="">
                                <div className="uk-navbar-right">
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active">
                                            <Button
                                                className="uk-navbar-item uk-text-primary uk-text-bold"
                                                modifier="link"
                                                onClick={openSidebar}
                                            >
                                                <div className="uk-flex uk-flex-column">
                                                    <div data-uk-navbar-toggle-icon="ratio: 1.7" />
                                                    <div className="uk-text-uppercase uk-text-light">Menü</div>
                                                </div>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <HolidayBanner />
                <CouponBanner />
            </div>
            <Offcanvas overlay isVisible={isNavSidebarVisible} onIsVisibleChange={visible => setIsNavSidebarVisible(visible)} flip={true}>
                <div className="uk-offcanvas-bar">
                    <button className="uk-offcanvas-close" type="button" data-uk-close="" onClick={closeSidebar} />
                    <span className='uk-text-bold menu-black' onClick={closeSidebar}>Menü</span>
                    <ShopMenuList categories={categoriesWithoutReparaturUndWartung} closeSidebar={closeSidebar} data-uk-nav="" />
                    <ul className="uk-nav-default uk-nav-parent-icon uk-margin-top" data-uk-nav="">
                        {reparaturUndWartungCategory.map(category => (
                            <li key={category._id} className={classnames({ 'uk-active': isLinkActive(category) })} onClick={closeSidebar}>
                                <CategoryLink category={category} className="uk-text-bold">
                                    {category.title}
                                </CategoryLink>
                            </li>
                        ))}
                    </ul>
                    {serviceNavigatorStatus && (
                        <ul className="uk-nav-default uk-nav-parent-icon uk-margin-top" data-uk-nav="">
                            <li onClick={closeSidebar}>
                                <LinkSelector
                                    to='/servicenavigator'
                                    label='Service-Navigator'
                                    className="uk-text-bold"
                                    type='internalLink'
                                />
                            </li>
                        </ul>
                    )}
                    {!_.isEmpty(links) && (
                        <div className="uk-margin-large-top">
                            <LinkSelector to={carDealerLink.to} type={carDealerLink.type} label={<h2>{carDealerLink.label}</h2>} />
                            <ul className="uk-nav-default uk-nav-parent-icon" data-uk-nav="">
                                {links.map((item, index) => (
                                    <li key={index}>
                                        <LinkSelector
                                            to={item.to}
                                            action={item.action}
                                            category={item.category}
                                            label={item.label}
                                            type={item.type}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </Offcanvas>

        </>
    );
}

export default DefaultHeader;
